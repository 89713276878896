<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      table-height="calc(100vh - 205px)"
      api-url="/payable-list"
    ></custom-table>
  </div>
</template>

<script>
import { CUSTOM_VIEW_ADMIN_PAYABLE, SUPPLIER_TYPE_OPTIONS } from "@/libs/const";
export default {
  name: "Payable",
  components: {},
  data: () => ({
    customView: CUSTOM_VIEW_ADMIN_PAYABLE,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
  }),
  computed: {},
  created() {
    const supplierTypeOptions = [...SUPPLIER_TYPE_OPTIONS].map((opt) => ({
      text: this.$t(`labels.supplier_type_${opt}`),
      value: opt,
    }));
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.supplier_code"),
        placeholder: this.$t("labels.supplier_code"),
        name: "code",
        hasSort: true,
        sortName: "code",
        key: "code",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.type"),
        placeholder: this.$t("labels.type"),
        name: "type",
        hasSort: false,
        sortName: "type",
        key: "type",
        options: supplierTypeOptions,
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.name"),
        placeholder: this.$t("labels.name"),
        name: "name",
        hasSort: true,
        sortName: "name",
        key: "name",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payable"),
        placeholder: this.$t("labels.payable"),
        name: "payable",
        hasSort: true,
        sortName: "payable",
        key: "payable",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.paid"),
        placeholder: this.$t("labels.paid"),
        name: "paid",
        hasSort: true,
        sortName: "paid",
        key: "paid",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.debt"),
        placeholder: this.$t("labels.debt"),
        name: "debt",
        hasSort: true,
        sortName: "debt",
        key: "debt",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.temporary_payable"),
        placeholder: this.$t("labels.temporary_payable"),
        name: "temporary_payable",
        hasSort: true,
        sortName: "temporary_payable",
        key: "temporary_payable",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.temporary_debt"),
        placeholder: this.$t("labels.temporary_debt"),
        name: "temporary_debt",
        hasSort: true,
        sortName: "temporary_debt",
        key: "temporary_debt",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
